<template>
  <div class='partners'>
    <div class="container">
      <div class="forum-page__logo forum-logo">
        <div
          class="forum-logo__icon"
          data-aos="fade-down"
          data-aos-duration="800"
        >
          <svg width="298" height="173" viewBox="0 0 298 173" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M106.832 3.58114L108.616 28.3346H109.339C119.706 8.97704 134.341 0 152.93 0C176.51 0 189.095 9.67875 197.244 28.3346H197.943C212.602 5.73466 229.744 0 244.765 0C289.32 0 298 30.851 298 62.7425V172.137H240.835V76.0266C240.835 60.6132 241.534 45.5385 225.115 45.5385C207.949 45.5385 205.104 59.1614 205.104 74.9378V172.137H147.915V76.0266C147.915 60.6132 148.638 45.5385 132.195 45.5385C115.053 45.5385 112.184 59.1614 112.184 74.9378V172.137H55.7424V70.9937C41.4451 71.3566 26.0871 63.1055 26.8104 41.5944C10.729 40.5298 0 29.3992 0 8.61409V3.58114H106.832Z" fill="#F50F64"/>
          </svg>
        </div>
        <div
          class="forum-logo__text"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          Magnum <br />Marketing <br />Forum 2022
        </div>
      </div>
      <div class="forum-partners__inner partners-grid">
        <img
          class="forum-partners__img one"
          src="@/assets/img/forum/cocacola.svg"
          alt=""
        />
        <img
          class="forum-partners__img two"
          src="@/assets/img/forum/mars.svg"
          alt=""
        />
        <img
          class="forum-partners__img three"
          src="@/assets/img/forum/mondelez.svg"
          alt=""
        />
        <img
          class="forum-partners__img four"
          src="@/assets/img/forum/tanuki.svg"
          alt=""
        />
        <img
          class="forum-partners__img five"
          src="@/assets/img/forum/bat.svg"
          alt=""
        />
        <img
          class="forum-partners__img six"
          src="@/assets/img/forum/jelzin.png"
          alt=""
        />
        <img
          class="forum-partners__img seven"
          src="@/assets/img/forum/carl.svg"
          alt=""
        />
        <img
          class="forum-partners__img eight"
          src="@/assets/img/forum/almawine.svg"
          alt=""
        />
        <img
          class="forum-partners__img nine"
          src="@/assets/img/forum/monkey.png"
          alt=""
        />
        <img
          class="forum-partners__img ten"
          src="@/assets/img/forum/maxi.png"
          alt=""
        />
        <img
          class="forum-partners__img eleven"
          src="@/assets/img/forum/jacobs.png"
          alt=""
        />
        <img
          class="forum-partners__img twelve"
          src="@/assets/img/forum/tiger.svg"
          alt=""
        />
        <img
          class="forum-partners__img thirteen"
          src="@/assets/img/forum/rgbrands.png"
          alt=""
        />
        <img
          class="forum-partners__img fourteen"
          src="@/assets/img/forum/heineken.svg"
          alt=""
        />

        <img
          class="forum-partners__img fifteen"
          src="@/assets/img/forum/little-brazil.svg"
          alt=""
        />
        <img
          class="forum-partners__img sixteen"
          src="@/assets/img/forum/colgate.png"
          alt=""
        />
        <img
          class="forum-partners__img seventeen"
          src="@/assets/img/forum/grants.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Partners'
  }
</script>